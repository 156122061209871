<template>
    <div class="resume">
        <headLine msg='简历'></headLine>   
        <el-table
            :data="tableData"
            border
            style="width: 100%">
            <el-table-column
            type="index"
            label="#序号"
            width="60">
            </el-table-column>
            <el-table-column
            width="180"
             :show-overflow-tooltip="true"
            label="双选会时间">
                <template slot-scope="scope">
                    <span>{{scope.row.startTime}}~{{scope.row.endTime}}</span>
                </template>
            </el-table-column>
            <el-table-column
            :show-overflow-tooltip="true"
            prop="dcName"
            width="200"
            label="双选会名称">
            </el-table-column>
            <el-table-column
            :show-overflow-tooltip="true"
            label="简历总数(个)">
                <template slot-scope="scope">
                    <span>{{scope.row.resumeDetail.resumeNum}}</span>
                </template>
            </el-table-column>
             <el-table-column
            :show-overflow-tooltip="true"
            label="未处理(个)">
                 <template slot-scope="scope">
                    <span class="red" >{{scope.row.resumeDetail.unRead}}</span>
                </template>
            </el-table-column>
            <el-table-column
            :show-overflow-tooltip="true"
            label="邀约面试(个)">
                 <template slot-scope="scope">
                    <span>{{scope.row.resumeDetail.interview}}</span>
                </template>
            </el-table-column>
            <!-- <el-table-column
            :show-overflow-tooltip="true"
            label="面试通过(个)">
                 <template slot-scope="scope">
                    <span>{{scope.row.resumeDetail.improper}}</span>
                </template>
            </el-table-column> -->
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button
                    size="mini" 
                    type="primary"
                    @click="lookResumeDetail(scope.row.id)">
                    查看详情
                    </el-button>         
                </template>      
            </el-table-column>           
        </el-table>
     
    </div>
</template>
<script>
export default {   
    data() {
        return {
            tableData:[],
            companyId:'',         
        }
    },
    methods: {
        lookResumeDetail(id){
            this.$router.push({path:"/resumeDetail",query:{id}})
        } ,
        getInitList(){
            let url=`/hr/resume/dc/list?companyId=${this.companyId}`;      
            this.$htp.get_(url).then((res)=>{
                this.tableData=res.data;           
            }) 
        },
        
    },
    mounted(){
        this.companyId=sessionStorage.getItem('id')
        this.getInitList()    
    }
}
</script>
<style lang="scss" scoped>
@import '../../assets/css/common.scss';
.red{
    font-weight: bold;
}
</style>
